import React from "react";
import cardMouseEffect from "common/cardMouseEffect";
import { thumparallaxDown } from "common/thumparallax";
import { useTranslation } from 'react-i18next';

const MinimalArea = () => {
    const { t } = useTranslation();

    React.useEffect(() => {
        cardMouseEffect(document.querySelectorAll(".feat .items"));
        setTimeout(() => {
            thumparallaxDown();
        }, 1000);
    }, []);

    return (
        <section className="min-area sub-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="img">
                            <img
                                className="thumparallax-down"
                                src="/img/office.jpg"
                                alt=""
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 valign">
                        <div className="content pt-0">
                            <h4 className="wow color-font">{ t('about.aboutUs') }</h4>

                            <p className="wow txt" data-splitting>
                                { t('about.foundedWithMission') }
                            </p>

                            <ul className="feat">
                                <li className="wow fadeInUp" data-wow-delay=".2s">
                                    <h6>
                                        <span>1</span> { t('about.ourMission') }
                                    </h6>
                                    <p>
                                        { t('about.ourMissionDescription') }
                                    </p>
                                </li>

                                <li className="wow fadeInUp" data-wow-delay=".4s">
                                    <h6>
                                        <span>2</span> { t('about.ourGoals') }
                                    </h6>
                                    <p>
                                        { t('about.ourGoalsDescription') }
                                    </p>
                                </li>

                                <li className="wow fadeInUp" data-wow-delay=".6s">
                                    <h6>
                                        <span>3</span> { t('about.whyUs') }
                                    </h6>
                                    <p>
                                        { t('about.whyUsDescription') }
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MinimalArea;
