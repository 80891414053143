import React from "react";
import { useTranslation } from "react-i18next";
import Split from "../Split";

const AboutIntro = () => {
  const { t } = useTranslation();

  return (
    <section className="intro-section section-padding pb-40">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="htit sm-mb30">
              <h4>{t('about.info.title')}</h4>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text">
              <Split>
                <p
                  className="wow txt mb-10 words chars splitting"
                  data-splitting
                >
                  {t('about.info.paragraph1')}
                </p>
                <p className="wow txt words chars splitting" data-splitting>
                  {t('about.info.paragraph2')}
                </p>
              </Split>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutIntro;
